<template>
  <div class="step">
    <div class="title">{{ $t("guide.configuration") }}</div>
    <div class="sub">{{ $t("guide.configurationSub") }}</div>
    <div class="formBox">
      <div class="f1">
        <div class="box">
          <div class="label">{{ $t("common.exchange") }}</div>
          <div>{{ exchangeData.exchange }}</div>
        </div>
        <div class="box">
          <div class="label">{{ $t("common.pair") }}</div>
          <div>{{ exchangeData.yenUnit1 }}({{ exchangeData.yenUnit2 }})</div>
        </div>
      </div>
      <!-- cex -->
      <template v-if="exchangeData.web_type == '0'">
        <div class="cardBox">
          <div class="cardSub">{{ $t("guide.req") }}1</div>
          <div class="cardReq">
            <div>{{ $t("guide.req3_1") }}</div>
            <el-radio-group v-model="isOpen1" @change="radioChange1">
              <el-radio :label="1">{{ $t("common.yes") }}</el-radio>
              <el-radio :label="0">{{ $t("common.no") }}</el-radio>
            </el-radio-group>
          </div>
          <el-form :model="robotData" :rules="rules1" ref="robotForm1">
            <!-- 开盘时间 -->
            <el-form-item prop="0">
              <el-date-picker
                popper-class="guidePop"
                :disabled="isOpen1 == 1"
                :editable="false"
                :clearable="false"
                class="datetime inp"
                v-model="robotData[0]"
                value-format="timestamp"
                type="datetime"
                :placeholder="$t('robot.openingTime')"
                :picker-options="pickerNowAfter"
              />
            </el-form-item>
            <div class="row">
              <!-- 开盘价 -->
              <el-form-item prop="1">
                <el-input
                  :disabled="isOpen1 == 1"
                  :placeholder="$t('robot.openPrice')"
                  v-model="robotData[1]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <!-- 最高价 -->
              <el-form-item prop="2">
                <el-input
                  :disabled="isOpen1 == 1"
                  :placeholder="$t('robot.topPrice')"
                  v-model="robotData[2]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <!-- 回落价 -->
              <el-form-item prop="3">
                <el-input
                  :disabled="isOpen1 == 1"
                  :placeholder="$t('robot.downPrice')"
                  v-model="robotData[3]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <el-form :model="robotData" :rules="rules2" ref="robotForm2" key="cex">
          <div class="cardBox">
            <div class="cardSub">{{ $t("guide.req") }}2</div>
            <div class="cardReq">{{ $t("guide.req3_2") }}</div>
            <div class="row">
              <el-form-item prop="4" :label="$t('robot.orderPerNum')">
                <el-input
                  :placeholder="
                    $t('guide.fillIn', { name: $t('robot.orderPerNum') })
                  "
                  v-model="robotData[4]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="5" :label="$t('robot.sellPerNum')">
                <el-input
                  :placeholder="
                    $t('guide.fillIn', { name: $t('robot.sellPerNum') })
                  "
                  v-model="robotData[5]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item prop="6" :label="$t('guide.numberOfOrderLevels')">
                <el-input
                  :placeholder="
                    $t('guide.fillIn', {
                      name: $t('guide.numberOfOrderLevels'),
                    })
                  "
                  v-model="robotData[6]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="7" :label="$t('guide.spreadPerLevel')">
                <el-input
                  :placeholder="
                    $t('guide.fillIn', { name: $t('guide.spreadPerLevel') })
                  "
                  v-model="robotData[7]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
            </div>
          </div>

          <div class="cardBox">
            <div class="cardSub">{{ $t("guide.req") }}3</div>
            <div class="cardReq">{{ $t("guide.req3_3") }}</div>
            <div class="row">
              <el-form-item prop="8">
                <el-input
                  :placeholder="$t('guide.dailyRequiredWashTradingVolume')"
                  v-model="robotData[8]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item prop="9">
                <el-input
                  :placeholder="
                    $t('guide.minValueOfTheQuantityRangePerWashTrade')
                  "
                  v-model="robotData[9]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
              <el-form-item prop="10">
                <el-input
                  :placeholder="
                    $t('guide.maxValueOfTheQuantityRangePerWashTrade')
                  "
                  v-model="robotData[10]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item prop="11">
                <el-input
                  :placeholder="$t('robot.priceDecimal')"
                  v-model="robotData[11]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="12">
                <el-input
                  :placeholder="$t('robot.quantityDecimal')"
                  v-model="robotData[12]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
      <!-- dex -->
      <template v-else>
        <div class="cardBox">
          <div class="cardSub">{{ $t("guide.req") }}1</div>
          <div class="cardReq">
            <div>{{ $t("guide.reqDex3_1") }}</div>
            <el-radio-group v-model="isOpen2" @change="radioChange2">
              <el-radio :label="1">{{ $t("common.yes") }}</el-radio>
              <el-radio :label="0">{{ $t("common.no") }}</el-radio>
            </el-radio-group>
          </div>
          <el-form :model="robotDataDex" :rules="rulesDex" ref="robotFormDex1">
            <!-- 开盘时间 -->
            <el-form-item prop="0">
              <el-date-picker
                popper-class="guidePop"
                :disabled="isOpen2 == 1"
                :editable="false"
                :clearable="false"
                class="datetime inp"
                v-model="robotDataDex[0]"
                value-format="timestamp"
                type="datetime"
                :placeholder="$t('robot.openingTime')"
                :picker-options="pickerNowAfter"
              />
            </el-form-item>
            <div class="row">
              <!-- 最高买入价 -->
              <el-form-item prop="1">
                <el-input
                  :disabled="isOpen2 == 1"
                  :placeholder="$t('guide.maxPurchasePrice')"
                  v-model="robotDataDex[1]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <!-- Gas倍数 默认1 -->
              <el-form-item prop="2">
                <el-input
                  :disabled="isOpen2 == 1"
                  :placeholder="$t('guide.gasMultiplier')"
                  v-model="robotDataDex[2]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
              <!-- 开盘买入金额 -->
              <el-form-item prop="3">
                <el-input
                  :disabled="isOpen2 == 1"
                  :placeholder="$t('guide.openingPurchaseAmount')"
                  v-model="robotDataDex[3]"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <el-form
          :model="robotDataDex"
          :rules="rulesDex"
          ref="robotFormDex2"
          key="dex"
        >
          <div class="cardBox">
            <div class="cardSub">{{ $t("guide.req") }}2</div>
            <div class="cardReq">{{ $t("guide.reqDex3_2") }}</div>
            <div class="row">
              <el-form-item prop="4">
                <el-input
                  :placeholder="
                    $t('guide.fillIn', { name: $t('robot.priceRangePH1') })
                  "
                  v-model="robotDataDex[4]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
              <el-form-item prop="5">
                <el-input
                  :placeholder="
                    $t('guide.fillIn', { name: $t('robot.priceRangePH2') })
                  "
                  v-model="robotDataDex[5]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
            </div>
          </div>

          <div class="cardBox">
            <div class="cardSub">{{ $t("guide.req") }}3</div>
            <div class="cardReq">{{ $t("guide.req3_3") }}</div>
            <div class="row">
              <el-form-item prop="6">
                <el-input
                  :placeholder="$t('guide.dailyRequiredWashTradingVolume')"
                  v-model="robotDataDex[6]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item prop="7">
                <el-input
                  :placeholder="
                    $t('guide.minValueOfTheQuantityRangePerWashTrade')
                  "
                  v-model="robotDataDex[7]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
              <el-form-item prop="8">
                <el-input
                  :placeholder="
                    $t('guide.maxValueOfTheQuantityRangePerWashTrade')
                  "
                  v-model="robotDataDex[8]"
                  autocomplete="off"
                  class="inp"
                >
                  <div slot="suffix">{{ exchangeData.yenUnit2 }}</div>
                </el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
    </div>
    <div class="g_btn btn" @click="nextStep">{{ $t("guide.runRobot") }}</div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
// api
import { robotParam } from "@/api/guide";
export default {
  props: ["exchangeData"],
  computed: {
    ...mapGetters(["account"]),
  },
  data() {
    return {
      pickerNowAfter: {
        disabledDate(time) {
          // 获取今天的日期，不包括时间
          let today = new Date();
          today.setHours(0, 0, 0, 0);

          // 禁止选择今天之前的日期
          return time.getTime() < today.getTime();
        },
      },

      isOpen1: 0,
      robotData: {},
      rules1: {
        0: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.openingTime"),
          }),
          trigger: "change",
        },
        1: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.openPrice"),
          }),
          trigger: "blur",
        },
        2: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.topPrice"),
          }),
          trigger: "blur",
        },
        3: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.downPrice"),
          }),
          trigger: "blur",
        },
      },
      rules2: {
        4: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.orderPerNum"),
          }),
          trigger: "blur",
        },
        5: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.sellPerNum"),
          }),
          trigger: "blur",
        },
        6: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.numberOfOrderLevels"),
          }),
          trigger: "blur",
        },
        7: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.spreadPerLevel"),
          }),
          trigger: "blur",
        },

        8: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.dailyRequiredWashTradingVolume"),
          }),
          trigger: "blur",
        },
        9: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.minValueOfTheQuantityRangePerWashTrade"),
          }),
          trigger: "blur",
        },
        10: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.maxValueOfTheQuantityRangePerWashTrade"),
          }),
          trigger: "blur",
        },
        11: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.priceDecimal"),
          }),
          trigger: "blur",
        },
        12: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.quantityDecimal"),
          }),
          trigger: "blur",
        },
      },

      isOpen2: 0,
      robotDataDex: {},
      rulesDex: {
        0: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.openingTime"),
          }),
          trigger: "change",
        },
        1: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.maxPurchasePrice"),
          }),
          trigger: "blur",
        },
        2: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.gasMultiplier"),
          }),
          trigger: "blur",
        },
        3: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.openingPurchaseAmount"),
          }),
          trigger: "blur",
        },

        4: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.priceRangePH1"),
          }),
          trigger: "blur",
        },
        5: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("robot.priceRangePH2"),
          }),
          trigger: "blur",
        },

        6: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.dailyRequiredWashTradingVolume"),
          }),
          trigger: "blur",
        },
        7: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.minValueOfTheQuantityRangePerWashTrade"),
          }),
          trigger: "blur",
        },
        8: {
          required: true,
          message: this.$t("guide.fillIn", {
            name: this.$t("guide.maxValueOfTheQuantityRangePerWashTrade"),
          }),
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    // 清空验证提示
    // cex
    radioChange1() {
      this.$refs.robotForm1.clearValidate();
    },
    clearValidate1() {
      this.$refs.robotForm1?.clearValidate();
      this.$refs.robotForm2?.clearValidate();
    },
    // dex
    radioChange2() {
      this.$refs.robotFormDex1.clearValidate();
    },
    clearValidate2() {
      this.$refs.robotFormDex1?.clearValidate();
      this.$refs.robotFormDex2?.clearValidate();
    },

    // 验证
    validateForm(formRef) {
      return new Promise((resolve) => {
        formRef.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            reject(new Error("Form validation failed"));
          }
        });
      });
    },

    async nextStep() {
      console.log("cexData", this.robotData);
      console.log("dexData", this.robotDataDex);

      var opening_time = "",
        dataObj = {};
      if (this.exchangeData.web_type == 0) {
        // cex
        try {
          if (this.isOpen1 == 0) {
            //未开盘-需要机器人
            await Promise.all([
              this.validateForm(this.$refs.robotForm1),
              this.validateForm(this.$refs.robotForm2),
            ]);
            opening_time = Number(
              new Decimal(this.robotData[0]).div(new Decimal(1000))
            );
          } else {
            await this.validateForm(this.$refs.robotForm2);
            opening_time = "";
          }
          dataObj = JSON.parse(JSON.stringify(this.robotData));
          dataObj[0] = dataObj[0]
            ? Number(new Decimal(dataObj[0]).div(new Decimal(1000)))
            : "";
        } catch (error) {
          return false;
        }
      } else {
        // dex
        try {
          if (this.isOpen2 == 0) {
            //未开盘-需要机器人
            await Promise.all([
              this.validateForm(this.$refs.robotFormDex1),
              this.validateForm(this.$refs.robotFormDex2),
            ]);
            opening_time = Number(
              new Decimal(this.robotDataDex[0]).div(new Decimal(1000))
            );
          } else {
            await this.validateForm(this.$refs.robotFormDex2);
            opening_time = "";
          }
        } catch (error) {
          return false;
        }

        dataObj = JSON.parse(JSON.stringify(this.robotDataDex));
        dataObj[0] = dataObj[0]
          ? Number(new Decimal(dataObj[0]).div(new Decimal(1000)))
          : "";
      }
      // 获取对象中的最大键值
      let maxIndex = Math.max(...Object.keys(dataObj).map(Number));
      // 创建一个长度为 maxIndex + 1 的数组，初始值为空字符串
      let valuesArray = Array.from({ length: maxIndex + 1 }, () => "");
      // 填充数组
      for (let key in dataObj) {
        valuesArray[key] = dataObj[key];
      }
      // 将数组转换为空格间隔的字符串
      const robot_param = valuesArray.join(" ");

      const loading = Loading.service({
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });

      robotParam({
        web: this.exchangeData.web,
        account: this.account,
        opening_time,
        robot_param,
      })
        .then((res) => {
          loading.close();
          this.$message({
            message: this.$t("guide.settingSuc"),
            type: "success",
            center: true,
            customClass: "shotMsg",
          });
          // 关闭新手引导
          this.$store.dispatch("user/setNovice", "0");
        })
        .catch((err) => {
          loading.close();
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import './step.styl';
@import './step3.styl';
</style>